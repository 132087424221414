import axios from "axios";
import { URLConfig } from "../config/URL.Config";
import { SessionConfig } from "../config/SessionConfig";

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_VERSION,
  headers: {
    "Content-Type": "application/json",
  },
});

const SessionsService = {
  postNewSession: async (sessionModel: any) => {
    try {
      const response = await axiosInstance.post(
        SessionConfig.postNewSession,
        sessionModel
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  getSessionByEventId: async (eventId: any) => {
    try {
      const response = await axiosInstance.get(
        SessionConfig.getSessionByEventId + "?eventId=" + eventId
      );
      if (response.status == 200 || response.status == 204) {
        return response;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  getSessionById: async (sessionId: string) => {
    try {
      const response = await axiosInstance.get(
        SessionConfig.GetSessionById + "?sessionId=" + sessionId
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  getSessionsByUserId: async (userId: string) => {
    try {
      const response = await axiosInstance.get(
        SessionConfig.GetSessionsByUserId + "?userId=" + userId
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  updateSessionStatus: async (sessionId: any, status: any) => {
    try {
      const response = await axiosInstance.get(
        SessionConfig.updateStatus +
          "?sessionId=" +
          sessionId +
          "&sessionStatus=" +
          status
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  withdrawalSession: async (
    sessionId: string,
    withdrawStatus: boolean,
    withdrawReason?: string
  ) => {
    try {
      const response = await axiosInstance.post(
        SessionConfig.withdrawal +
          "?sessionId=" +
          sessionId +
          "&withdrawStatus=" +
          withdrawStatus +
          "&withdrawReason=" +
          withdrawReason
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  sessionTimeUpdate: async (sessionDetails: any) => {
    try {
      const response = await axiosInstance.post(
        SessionConfig.updateSession,
        sessionDetails
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  updateAgendaBuilder: async(sessionUpdateModel : any) => {
    try {
      const response = await axiosInstance.put(
        SessionConfig.updateAgendaBuilder,
        sessionUpdateModel
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  NominateAsOrganizer: async (data: any) => {
    try {
      const response = await axiosInstance.post(
        SessionConfig.postNewSessionAsOrganizer,
        data
      );
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  getLocalStorage: () => {
    return localStorage.getItem("userDetails");
  },

  // getSessionsByUEventId: (eventId) => {
  //   return axios.get(
  //     `https://prod-oratorengage-api.azurewebsites.net/api/v1/Event/GetEventDashboard?id=3fa85f64-5717-4562-b3fc-2c963f66afa6`
  //   );
  // },

  // getSessionById: (sessionId: string) => {
  //   return axiosInstance.get(
  //     `https://prod-oratorengage-api.azurewebsites.net/api/v1/Session/GetSessionsBySessionId?sessionId=${sessionId}`
  //   );
  // },

  // sessionTimeUpdate: (sessionTime: any) => {
  //   return axiosInstance.post(
  //     "https://prod-oratorengage-api.azurewebsites.net/api/v1/Session/UpdateSession",
  //     sessionTime
  //   );
  // },
  // updateSessionStatus: (sessionId: any, status: any) => {
  //   return axiosInstance.put(
  //     `https://prod-oratorengage-api.azurewebsites.net/api/v1/Session/UpdateStatus?sessionId=${sessionId}&status=${status}`,
  //     ""
  //   );
  // },

  // getSpeakerBySpeakerId: (speakerId) => {
  //   return axiosInstance.get(`${URLConfig.getSpeaker}id=${speakerId}`);
  // },

  // updateSessionStatus: (sessionId, status) => {
  //   return axiosInstance.put(
  //     `${URLConfig.updateSessionStatus}?sessionId=${sessionId}&status=${status}`,
  //     ""
  //   );
  // },

  // sessionTimeUpdate: (sessionTime) => {
  //   return axiosInstance.post(URLConfig.sessionTimeUpdate, sessionTime);
  // },

  // getAllSpeakers: () => {
  //   return axiosInstance.get(URLConfig.getAllSpeakers);
  // },
};

export default SessionsService;
