import moment from "moment";
import { momentLocalizer, Views } from "react-big-calendar";
import AppointmentEvent from "./Appointment";

const localizer = momentLocalizer(moment);

const components: any = {
  event: ({ event }: any) => {
    const data = event?.data;
    if (data?.appointment)
      return <AppointmentEvent appointment={data?.appointment} />;
    return null;
  },
};

export const props = {
  components,
  localizer,
  defaultDate: "2024-07-16",
  defaultView: Views.DAY,
  view : Views.DAY,
  max: moment("2024-07-16T19:00:00").toDate(),
  min: moment("2024-07-16T08:00:00").toDate(),
};
