import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import SessionsService from "../../../../services/sessionService";
import "./SessionList.scss";
import { HashLoader } from "react-spinners";
import { exportToExcel } from "../../../../helper/ConvertToExcel";
import { formatDate } from "../../../../helper/DateFormatter";

interface ListEvents {
  data: any[];
}

const ListSessionsComponent = () => {
  const [loading, setLoading] = useState(false);
  const [listEvents, setListEvents] = useState<ListEvents | null>(null);
  const [originalSessions, setOriginalSessions] = useState<any[]>([]);
  const { eventId } = useParams<{ eventId: string }>();
  const [showMenu, setShowMenu] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({
    Nominated: true,
    Accepted: true,
    "Accept Queue": true,
    "Decline Queue": true,
    Declined: true,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFirstFilter, setSelectFirstFilter] = useState("techStack");
  const [selectedSecondFilter, setSelectSecondFilter] = useState("sessionType");

  const navigate = useNavigate();

  useEffect(() => {
    getSessionsByEventId(eventId);
  }, [eventId]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const getSessionsByEventId = (eventId: string) => {
    setLoading(true);
    SessionsService.getSessionByEventId(eventId)
      .then((resData) => {
        if (resData && resData.data) {
          setListEvents(resData);
          setLoading(false);
          setOriginalSessions(resData.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error fetching sessions:", err);
      });
  };

  const filterSessions = () => {
    if (!listEvents || !listEvents.data) {
      return [];
    }

    let filtered = listEvents.data;

    if (searchQuery.trim() !== "") {
      filtered = filtered.filter((session) => {
        const speakerName =
          `${session?.speakers[0]?.users?.firstName} ${session?.speakers[0]?.users?.lastName}`.toLowerCase();
        const sessionTitle = session.title.toLowerCase();
        const sessionStatus =
          statusMapping[session.sessionStatus]?.toLowerCase();
        const query = searchQuery.toLowerCase();

        return (
          speakerName.includes(query) ||
          sessionTitle.includes(query) ||
          (sessionStatus && sessionStatus.includes(query))
        );
      });
    }

    return filtered;
  };

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected);
  };

  const statusMapping = {
    0: "Nominated",
    1: "Accepted",
    2: "Accept Queue",
    3: "Decline Queue",
    4: "Declined",
  };

  const navigateToDetailSession = (sessionId: string) => {
    navigate(`/organizer/session-list-details/${sessionId}`);
  };

  const downloadAsExcel = () =>{

    const processedTitles = new Set();

const selectedFields = originalSessions.flatMap((session) => 
  session.speakers.map((speaker) => {
    // Check if the session title has already been processed
    const isTitleProcessed = processedTitles.has(session.title);

    // Add the session title to the set if it's not already processed
    if (!isTitleProcessed) {
      processedTitles.add(session.title);
    }

    return {
      Title: isTitleProcessed ? '' : session.title,
      Description: isTitleProcessed ? '' : session.description,
      AdditionalNotes: isTitleProcessed ? '' : session.additionalNotes,
      Language: isTitleProcessed ? '' : session.language,
      Category: isTitleProcessed ? '' : session.category,
      TechStack: isTitleProcessed ? '' : session.techStack,
      SessionType: isTitleProcessed ? '' : session.sessionType,
      SessionLevel: isTitleProcessed ? '' : session.sessionLevel,
      SessionStatus: isTitleProcessed ? '' : statusMapping[session.sessionStatus] || "NIL",
      SubmittedOn : isTitleProcessed ? '' : formatDate(session.createdOn) || "NIL",
      IsWithdrawn: isTitleProcessed ? '' : session.isSessionWithdrawn ? 'YES' : 'NO',
      WithdrawReason: isTitleProcessed ? '' : session.withdrawReason || '',
      WithdrawDateTime: isTitleProcessed ? '' : session.withdrawDateTime || '',
      // Speaker-specific details
      SpeakerName: speaker.users.firstName + ' '+ speaker.users.lastName,
      SpeakerEmail: speaker.users.email,
      SpeakerBio: speaker.biography,
      SpeakerTagline: speaker.tagLine
      // Add more speaker fields if needed
    };
  })
);


    exportToExcel(selectedFields, 'Session List -'+originalSessions[0].event.eventName, 'Sessions')
  }

  const filteredSessions = filterSessions();
  const pageCount = Math.ceil(filteredSessions.length / itemsPerPage);
  const currentSessions = filteredSessions.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div>
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
      <div className="bg-white min-h-screen p-4 text-sm">
        {listEvents && listEvents.data && listEvents.data?.length > 0 ? (
          <div className="row">
            <div className="col-lg-12 b grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="flex p-5 justify-between">
                    <div className="search-head w-4/6">
                      <input
                        type="text"
                        className="form-control search-bar rounded-full"
                        placeholder="Search sessions with any key of name, session title, status"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <div className="search-icon">
                        <img src="/session/searchIcon.svg" alt="search-icon" />
                      </div>
                    </div>
                    <a href={`/organizer/nominate-as-organizer/${eventId}`}>
                      <button
                        type="submit"
                        className={`px-7 py-2  rounded-full bg-[#625ffb] text-white`}
                      >
                        Nominate Session
                      </button>
                    </a>
                  </div>

                  <div className="flex px-5 justify-end">
                    <button
                      type="button"
                      onClick={()=>downloadAsExcel()}
                      className="text-white bg-green-600 hover:bg-blue-800 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-green-600 dark:hover:bg-green-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-5 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                        />
                      </svg>
                      Download
                    </button>
                  </div>

                  <div className="session-list bg-white px-2 rounded-md m-3">
                    <div className="font-bold text-white p-2 bg-[#625ffb] rounded-md py-2 session-header mb-4 flex items-center gap-4">
                      <div className="session-item w-2/6 text-left">Title</div>
                      <div className="session-item w-1/6 text-left">
                        Speaker(s)
                      </div>
                      <div className="session-item w-1/6 text-left ">
                        <select
                          value={selectedFirstFilter}
                          onChange={(e) => setSelectFirstFilter(e.target.value)}
                          className="form-select bg-[#625ffb]"
                        >
                          <option value="techStack">techStack</option>
                          <option value="sessionLevel">sessionLevel</option>
                          <option value="sessionType">sessionType</option>
                          <option value="category">category</option>
                        </select>
                      </div>
                      <div className="session-item w-1/6 text-left ">
                        <select
                          value={selectedSecondFilter}
                          onChange={(e) =>
                            setSelectSecondFilter(e.target.value)
                          }
                          className="form-select bg-[#625ffb]"
                        >
                          <option value="techStack">techStack</option>
                          <option value="sessionLevel">sessionLevel</option>
                          <option value="sessionType">sessionType</option>
                          <option value="category">category</option>
                        </select>
                      </div>
                      <div className="session-item w-1/6 text-left">Status</div>
                    </div>
                    {currentSessions.map((session) => (
                      <div
                        key={session.id}
                        className="session-row my-3 border-b-2 flex items-center gap-4 p-1"
                      >
                        <div
                          onClick={() => navigateToDetailSession(session.id)}
                          className="text-[#625ffb] font-bold cursor-pointer session-item w-2/6 text-left custom-ellipsis"
                        >
                          {session.title}
                          {session?.isSessionWithdrawn && (
                            <img className="w-4 h-4" src="/session/retry.svg" />
                          )}
                        </div>
                        <div className="session-speakers text-left flex flex-col gap-2 w-1/6">
                          {session?.speakers?.map((speaker, index) => (
                            <div
                              key={index}
                              className="speaker-item flex gap-3 items-center"
                            >
                              <div className="speaker-photo">
                                <img
                                  src={
                                    session?.speakers[index]?.photoPath ||
                                    "../../../../assets/img/profile-placeholder-2.png"
                                  }
                                  className="profile rounded-full"
                                  alt="Profile Avatar"
                                />
                              </div>
                              <div className="speaker-name text-start">
                                {`${speaker?.users?.firstName} ${speaker?.users?.lastName}`}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="session-item  ml-2 w-1/6 custom-ellipsis">
                          {session[`${selectedFirstFilter}`]}
                        </div>
                        <div className="session-item ml-4 w-1/6 custom-ellipsis">
                          <div className="">
                            {session[`${selectedSecondFilter}`]}
                          </div>
                        </div>
                        <div className="session-item -ml-6 w-1/6">
                          <button
                            className={`custom-font-size ${
                              statusMapping[session.sessionStatus] ===
                              "Nominated"
                                ? "badge badge-warning"
                                : statusMapping[session.sessionStatus] ===
                                  "Accepted"
                                ? "badge badge-success"
                                : statusMapping[session.sessionStatus] ===
                                  "Accept Queue"
                                ? "badge badge-info"
                                : statusMapping[session.sessionStatus] ===
                                  "Decline Queue"
                                ? "badge badge-danger"
                                : "status-decline"
                            }`}
                          >
                            {statusMapping[session.sessionStatus] || "NIL"}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  {pageCount > 1 && (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : !loading ? (
          <p className="no-session">No sessions available.</p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ListSessionsComponent;