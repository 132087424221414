export function formatDate(isoString: string): string {
    const date = new Date(isoString);
  
    // Correct options for date formatting
    const optionsDate: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    };
  
    // Correct options for time formatting
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
  
    const formattedDate = date.toLocaleDateString('en-GB', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
  
    return `${formattedDate} ${formattedTime}`;
  }
  
  const isoDate = "2024-08-21T14:25:45.698";
  const formatted = formatDate(isoDate);
  console.log(formatted); // Outputs: "21 Aug 2024 2:25 PM"
  